import axios from 'axios'

const httpClient = axios.create({
  baseURL: document.location.hostname == "manage-reservation.menual.net"
    ? "https://menual.azurewebsites.net"
    : "https://menualdev.azurewebsites.net",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  }
});

httpClient.serializeJSON = (data) => {
    return Object.keys(data)
      .map(function (keyName) {
        return (
          encodeURIComponent(keyName) + '=' + encodeURIComponent(data[keyName])
        );
      })
      .join('&');
  }

export default httpClient

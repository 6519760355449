import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import httpClient from './httpClient'

// First, checks if it isn't implemented yet.
if (!String.prototype.format) {
    String.prototype.format = function () {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined'
                ? args[number]
                : match
                ;
        });
    };
}

const lang = {
    en: {
        "cancel-title": "Cancel your reservation",
        "cancel-description": "Your reservation from {0}, was created if you need to cancel it please click here:",
        "cancel-btn": "Cancel",
        "invalid-reservation": "Invalid reservation",
        "reservation-canceled": "Your reservation was canceled",
        "reservation-already-canceled": "Reservation is Canceled"
    },
    he: {
        "cancel-title": "ביטול הזמנה",
        "cancel-description": "נוצרה לך הזמנה ב-{0}, לביטול, לחצו פה:",
        "cancel-btn": "ביטול",
        "invalid-reservation": "אופס! יש פה טעות..",
        "reservation-canceled": "ההזמנה בוטלה בהצלחה, נשמח לשמוע ממך בעתיד :)",
        "reservation-already-canceled": "ההזמנה בוטלה בהצלחה, נשמח לשמוע ממך בעתיד :)"
    }
}

let canceled = false;
const Main = ({ }) => {
    let { id } = useParams()
    const [Reservation, setReservation] = useState(null)
    console.log("ReservationId", id)
    useEffect(() => {

        httpClient.get(`/ws/Reservation.asmx/GetReservation?id=${id}&Token=${''}`).then(({ data }) => {
            console.log("Got Reservation", data)
            setReservation(data)
        })
            .catch((e) => {
                console.warn("error getting reservations", e)
            })

    }, [])

    if (!Reservation) {
        return <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    }

    if (Reservation.Canceled) {
        return <div className="container" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: "100vh"
        }}>
            <div class="alert alert-danger" role="alert">
                <h1>{lang[Reservation.Lang]["reservation-already-canceled"]}</h1>
            </div>
        </div>
    }

    if (Reservation.CancelToken == null) {
        return <div className="container" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: "100vh"
        }}>
            <div class="alert alert-danger" role="alert">
                <h1>{lang[Reservation.Lang]["invalid-reservation"]}</h1>
            </div>
        </div>
    }

    return (
        <div className="container" style={{
            marginTop: 20
        }}>
            <img src="/images/menual_logo.png" style={{
                marginBottom: 50
            }} />
            <h1>{lang[Reservation.Lang]["cancel-title"]}</h1>

            <p>{(lang[Reservation.Lang]["cancel-description"]).format(Reservation.RestaurantName)}</p>

            <div className="shadow" style={{
                padding: 20,
                width: 400,
                marginBottom: 20,
                //border : "0.5px solid #333",
                borderTopLeftRadius: 20,
                borderBottomRightRadius: 20

            }}>
                <p style={{
                    fontWeight: "700"
                }}>{Reservation.Name}</p>
                <p>{dayjs(Reservation.TimeStamp).format("DD/MM/YY HH:mm")}</p>
                <p>{Reservation.Telephone}</p>
            </div>
            <button className="btn btn-primary" onClick={() => {

                if(canceled){
                    return;
                }
                canceled = true
                httpClient.post("/ws/Reservation.asmx/UserReservationCanceled", httpClient.serializeJSON({
                    ID: id,
                    CancelToken: Reservation.CancelToken
                }))
                    .then(({ data }) => {
                        alert(lang[Reservation.Lang]["reservation-canceled"])
                        window.location.reload()
                    })
            }
            }>{lang[Reservation.Lang]["cancel-btn"]}</button>
        </div>
    )
}

export default Main
